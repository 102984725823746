const getItem = (key) => {
    try {
        return localStorage.getItem(key);
    } catch (e) {
        // console.log(e);
        // return sessionStorage.getItem(key);
    }
};

const setItem = (key, value) => {
    try {
        localStorage.setItem(key, value);
    } catch (e) {
        // console.log(e);
        // sessionStorage.setItem(key, value);
    }
};

const removeItem = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (e) {
        // console.log(e);
        // sessionStorage.removeItem(key);
    }
};

const clear = () => {
    try {
        localStorage.clear();
    } catch (e) {
        // console.log(e);
        // sessionStorage.clear();
    }
};

export const UtilStorage = {
    getItem,
    setItem,
    removeItem,
    clear
};