import React from 'react';
import { withRouter } from 'react-router';
import WidgetCloseButton from './WidgetCloseButton';

import Footer from './Footer'
import { connect } from 'react-redux';

const BasePage = (props) => {
    const { widget } = props.settings;
    return (
        <div className="wrapper">
            <section className="section-container">
                {props.children}
            </section>

            <Footer />
            {(widget && widget.type !== 'inline') && <WidgetCloseButton />}
        </div>
    )
}
const mapStateToProps = state => ({ settings: state.settings });
export default connect(mapStateToProps)(withRouter(BasePage));
