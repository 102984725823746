import React from 'react';

const Heading = (props) => (
    <div className={['heading', props.className].join(' ')}>
        {renderStart(props)}


        <div className="heading-content">
            <h1 className="heading-title">{props.title}</h1>
            {renderSubtitle(props)}
        </div>

        {renderEnd(props)}
    </div>
)

const renderSubtitle = (props) => {
    if (!props.subtitle) {
        return;
    }
    return (
        <h2 className={['heading-subtitle', props.classNameSubtitle].join(' ')}>{props.subtitle}</h2>
    );
}

const renderStart = (props) => {
    if (!props.start) {
        return (<div className="heading-start" id="heading-start"></div>);
    }
    return (
        <div className="heading-start" id="heading-start">
            {props.start}
        </div>
    );
}

const renderEnd = (props) => {
    if (!props.end) {
        return (<div className="heading-end" id="heading-end"></div>);
    }
    return (
        <div className="heading-end" id="heading-end">
            {props.end}
        </div>
    );
}

export default Heading;
