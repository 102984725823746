import { include } from 'named-urls';

export default {
    notfound: '/404-not-found',
    error: '/500-error',
    binglisuccess: '/bingli-success',
    auth: include('/auth', {
        login: 'l/:path',
        forgotpassword: 'fp/:path',
        resetpassword: 'rp/:path',
        register: 'r/:path',
        confirm: 'c/:path'
    }),

    e: '/e/:type/:path',

    group: include('/g', {
        overview: ':path/:date?',
        optimal: 'o/:path/:date?',
        decisionTree: 'd/:path'
    }),

    client: include('/c', {
        overview: ':path/:date?/:returnGroup?',
        move: 'm/:path/:date?'
    }),

    type: include('/t', {
        overview: ':path',
        client: 'c/:path/:date?',
        group: 'g/:path/:date?',
        group_merged: 'gm/:path/:date?',
        move: 'm/:path/:date?',
        list: 'l/:path'
    }),

    appointment: include('/a', {
        overview: '',
        book: 'b/:path',
        client: 'cl/:path',
        confirmation: 'c/:path',
        edit: 'e/:path',
        manage: 'm/:path',
        print: 'p/:path',
        cancel: 'ca/:path',
        forgot: 'fo/:path'
    }),

    patient: include('/p', {
        overview: '',
        edit: 'e/:path',
        appointments: 'a'
    }),

    location: include('/location', {
        overview: '',
        search: 'search/:type?'
    }),

    medischeAnalysen: include('/medische-analysen', {
        code: ':code?'
    })
}